/* Default settings */
.ui-hero {
    max-width: 1366px;
    position: relative;
}
.ui-hero.has-action-block {
    margin-bottom: 180px;
}

.ui-hero .w-hero .w-hero-wrapper .title {
    margin: 0 auto;
    font-size: var(--font-size-heading3);
    line-height: 1.11;
    font-weight: bold;
    color: var(--common-white);
    text-align: center;
    width: 80%;
    z-index: 1;
    font-family: SansCondensedOri, SansCondensedOriFallback, sans-serif;
}

.ui-hero .w-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.ui-hero .image {
    height: 547px;
    width: 100%;
    background: var(--secondary-light);
}

.w-hero {
    z-index: 5;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-hero-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.ui-hero .w-hero .w-hero-wrapper .w-action-block {
    max-width: 360px;
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    padding: 0 25px 0 25px;
}

.ui-hero .w-hero .w-hero-wrapper .w-action-block .description {
    display: none;
}

.ui-hero .w-hero .w-hero-wrapper .w-action-block .headline {
    display: none;
}

.ui-hero .w-hero .w-hero-wrapper .no-header .w-action-block {
    position: absolute;
    bottom: 60px;
    margin-bottom: 0;
}

.ui-hero .image-format-A:before {
    padding-top: 0;
}

.ui-hero .w-action-block {
    margin: 0 auto 25px;
    width: calc(100% - 30px);
    max-width: 450px;
    padding: 15px;
    box-sizing: border-box;
    background-color: var(--common-white);
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    left: 0;
    right: 0;
    top: auto;
}

.ui-hero .w-form .ui-login {
    text-align: center;
}

.ui-hero .w-form .ui-login .social-login-item {
    width: 25%;
    text-align: center;
    margin: 0;
}

/* BP 1-2 */
@media only screen and (max-width: 767px) {
    .ui-hero .w-action-block {
        order: 1;
    }

    .ui-hero .w-hero .w-hero-wrapper .title {
        order: 0;
        word-wrap: break-word;
    }

    .ui-hero .w-hero .w-hero-wrapper .no-header .w-action-block {
        bottom: 30px;
    }
}

/* BP 3+ */
@media only screen and (min-width: 768px) {
    .ui-hero.has-action-block {
        margin-bottom: 120px;
    }

    .ui-hero .image {
        height: 520px;
        width: 100%;
        margin-right: 0;
        transform: translateX(0);
    }

    .ui-hero .w-hero .w-hero-wrapper .title {
        font-size: 6rem;
        line-height: 1.08;
    }

    .ui-hero .w-hero .w-hero-wrapper .w-action-block {
        width: 300px;
    }

    .ui-hero .k-button {
        width: 100%;
    }
}

/* BP 4+ */
@media only screen and (min-width: 920px) {
    .ui-hero .w-hero .w-hero-wrapper  .w-action-block {
        width: 400px;
    }
}

/* BP 5 */
@media only screen and (min-width: 1025px) {
    .ui-hero .w-hero .w-hero-wrapper .w-action-block {
        width: 450px;
    }

    .ui-hero .w-hero .w-hero-wrapper .title {
        font-size: 9rem;
        line-height: 1.06;
    }
}
